import S from './Links.module.scss';
import carmelArea from '../assets/imgs/linksMap.png';

function Links() {
  return (
    <div className={S.links}>
      <h1>Explore Carmel Valley</h1>

      <img 
        alt="maps screenshot of Carmel area" 
        src={carmelArea}
      />


      <div className={S.linkContain}>
        <div className={S.wineAndSpa}>
          <h2>Wine Tasting</h2>

          <p><a target="_blank" rel="noreferrer" href="https://g.page/folktale-winery-vineyards?share">Folktale Winery</a></p>
          <p><a target="_blank" rel="noreferrer" href="https://g.page/holman-ranch-tasting-room?share">Holman Ranch</a></p>
          <p><a target="_blank" rel="noreferrer" href="https://goo.gl/maps/WG9ivXs5M5yTBzRB7">Georis Winery</a></p>
          <p><a target="_blank" rel="noreferrer" href="https://goo.gl/maps/z7zEtmtPcJAmipJo7">Big Sur Vineyards</a></p>
          <p><a target="_blank" rel="noreferrer" href="https://goo.gl/maps/MyjUsgVf98ajtXym6">Bernardus Winery</a></p>
          <p><a target="_blank" rel="noreferrer" href="https://goo.gl/maps/1aSx6aBzgToD2So38">Cowgirl Winery</a></p>
          <p><a target="_blank" rel="noreferrer" href="https://goo.gl/maps/KtfMbqBHoADHsEDk8">Joullian Vineyards</a></p>

          <hr/>

          <h2>Hiking/Sightseeing</h2>
            <p><a target="_blank" rel="noreferrer" href="https://goo.gl/maps/HE33K8CnvaMfeKTt9">Carmel Beach</a></p>
            <p><a target="_blank" rel="noreferrer" href="https://goo.gl/maps/j4TCeDX5jsxu7HPp9">17 Mile Drive</a></p>
            <p><a target="_blank" rel="noreferrer" href="https://goo.gl/maps/c5MMtZ3EzK8mXFpcA">Point Lobos State Natural Reserve</a></p>
            <p><a target="_blank" rel="noreferrer" href="https://goo.gl/maps/bJAhitK9uy43vPiA6">Bird Island Trail</a></p>
            <p><a target="_blank" rel="noreferrer" href="https://goo.gl/maps/KJwCiQwntDagA7fy7">Garland Ranch Regional Park</a></p>

          <hr/>

          <h2>Golf - Carmel Valley</h2>
            <p><a target="_blank" rel="noreferrer" href="https://carmelvalleyranch.com/golf/">Carmel Valley Ranch!</a></p>
            <p><a target="_blank" rel="noreferrer" href="https://www.quaillodge.com/golf">Quail Lodge &amp; Golf Club</a></p>

          <h2>Golf - Pebble Beach</h2>
          <p><a target="_blank" rel="noreferrer" href="https://www.pebblebeach.com/golf/pebble-beach-golf-links/">Pebble Beach Golf Links</a></p>
          <p><a target="_blank" rel="noreferrer" href="https://www.pebblebeach.com/golf/the-links-at-spanish-bay/">The Links at Spanish Bay</a></p>
          <p><a target="_blank" rel="noreferrer" href="https://www.pebblebeach.com/golf/spyglass-hill-golf-course/">Spyglass Hill Golf Course</a></p>
          <p><a target="_blank" rel="noreferrer" href="https://www.pebblebeach.com/golf/del-monte-golf-course/">Del Monte Golf Course</a></p>

        </div>
      </div>
    </div>
  );
}

export default Links;
