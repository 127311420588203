import S from './Homepage.module.scss';
import homeImg from'../assets/imgs/homepage.jpg';

function Homepage() {
  return (
    <div className={S.homepage}>
      <h1>Welcome to Jen and Tim's Wedding!</h1>
      <img className={S.homeImg} alt="Super great shot of Jen and Tim" src={homeImg} />
      <p>We’ve created this website as a helpful resource for all of the need-to-know details about our wedding weekend. Here you’ll find a detailed schedule of events and locations, along with things to do in the Carmel Valley area.</p>

    </div>
  );
}

export default Homepage;
