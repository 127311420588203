import hyattMap from '../assets/imgs/hyattShuttleMap.png';

export const schedData = {
  "thursday": [
    {
      "name" : "Cheers before the big day!",
      "description": [
        "Come grab an evening drink with us at The Valley Kitchen bar (inside the main lobby at Carmel Valley Ranch) to say hi and cheers before our big day.",
        "Please note: This is not a hosted event.",
      ],
      "startTime": "9:30",
      "endTime": "10:30",
      "startSuff": "pm",
      "endSuff": "pm",
      "mapsLink": "https://goo.gl/maps/cMV1QYYCMMHhPtW7A",
      "linkText": "The Valley Kitchen"
    }
  ],
  "friday": [
    {
      "name" : "Shuttle departs the Hyatt Monterey",
      "description": [
        "Click the link below for the Hyatt map and shuttle pick up location.",
        "Please arrive to the shuttle pick up location a little early so you don’t miss the shuttle!",
      ],
      "startTime": "4:00",
      "endTime": "",
      "startSuff": "pm",
      "endSuff": "",
      "mapsLink": `${hyattMap}`,
      "linkText": "Hyatt Monteray Shuttle Map (Pick up location: South Entrance)"
    },
    {
      "name" : "Arrive at Carmel Valley Ranch",
      "description": [
        "The ceremony will be located at the Valley Lawn, right below the Clubhouse.",
        "For those driving to the venue, a map with the Clubhouse location can be found below. Limited parking is available at the venue.",
        "For those staying at Carmel Valley Ranch, the hotel shuttles will take you down to the Clubhouse.",
        "When you arrive, follow the paved walking path, located to the left of the Clubhouse, down to The Valley Lawn.",
        "Enjoy a pre-ceremony bubbly (a Jazz Cellars special!) as you walk in.",
        " Please note: ‘Carmel Valley Ranch Clubhouse and Driving Range’ is the correct location on Google maps, NOT ‘The Club House at Carmel Valley Ranch’."
      ],
      "startTime": "4:45",
      "endTime": "",
      "startSuff": "pm",
      "endSuff": "",
      "mapsLink": "https://goo.gl/maps/EP9WPL6aHrRujP6g9",
      "linkText": "Carmel Valley Ranch Clubhouse and Driving Range Lawn"
    },
    {
      "name" : "Ceremony",
      "description": [
      ],      
      "startTime": "5:00",
      "endTime": "",
      "startSuff": "pm",
      "endSuff": "",
      "mapsLink": ""
    },
    {
      "name" : "Cocktail hour",
      "description": [
      ],
      "startTime": "5:30",
      "endTime": "",
      "startSuff": "pm",
      "endSuff": "",
      "mapsLink": ""
    },
    {
      "name" : "Dinner, speeches & dancing!",
      "description": [
        "Speakers:",
        "Joe Lazzara (Father of The Bride)",
        "Austin Degenhardt (Best Man)",
        "Danielle Barbic (Matron of Honor)",
        "Mel Holbrook (Maid of Honor)",
      ],
      "startTime": "6:30",
      "endTime": "",
      "startSuff": "pm",
      "endSuff": "",
      "mapsLink": ""
    },
    {
      "name" : "Shuttle begins taking guests back to the Hyatt Monterey",
      "description": [
        "There will be 2 shuttles picking guests up from Carmel Valley Ranch and heading back to the Hyatt. The first shuttle will leave at 10:00 pm and the second will leave at 11:00 pm.",
        "If you plan on staying later, please note it can be a little hard to find an Uber on the way out from Carmel Valley Ranch."
      ],
      "startTime": "10:00",
      "endTime": "",
      "startSuff": "pm",
      "endSuff": "",
      "mapsLink": ""
    },
    {
      "name" : "The BEST DAY EVER comes to an end!",
      "description": [
      ],
      "startTime": "11:30",
      "endTime": "",
      "startSuff": "pm",
      "endSuff": "",
      "mapsLink": ""
    },
    
  ],
  'saturday': [
    {
      "name" : "Brunch with The Williams",
      "description": [
        "Brunch is located in the Vintner Room + Terrace (arrive to the main lobby and you will see a sign directing you to brunch).",
      ],
      "startTime": "11:00",
      "endTime": "1:00",
      "startSuff": "am",
      "endSuff": "pm",
      "mapsLink": "https://goo.gl/maps/WpWeo6fGgE2Z3yG1A",
      "linkText": "Main Lobby (signs will direct you to the Vinter Room + Terrace)."
    }
  ],
  'sunday': [
    {
      "name" : "Carmel Valley is your oyster!",
      "description": [
        "We hope you get to explore all that Carmel Valley has to offer. On the 'Links' page you’ll find a few of our favorite things to do around the area!",
      ],
      "startTime": "",
      "endTime": "",
      "mapsLink": "/links",
      "linkText": "Links to activities"
    }
  ]
}