import React, {useState} from 'react';
import S from './Schedule.module.scss';
// import * as data from '../config/schedData';
import { schedData as data } from '../config/schedData';
import gpsIcon from '../assets/imgs/gpsIcon.svg';

import thursDayWeath from '../assets/imgs/thursWeather.png';
import friDayWeath from '../assets/imgs/friWeather.png';
import satDayWeath from '../assets/imgs/satWeather.png';
import sunDayWeath from '../assets/imgs/sunWeather.png';

function Schedule() {
  const dayName = new Date().toLocaleDateString('en-us', { weekday: 'long' }).toLocaleLowerCase();
  const dayIsInWeekend = ['thursday', 'friday', 'saturday', 'sunday'].includes(dayName);
  const curDay = (dayIsInWeekend) ? dayName : 'thursday';
  const [isActive, setActive] = useState(curDay);
  const weatherPaths = {
    'thursday': thursDayWeath,
    'friday': friDayWeath,
    'saturday': satDayWeath,
    'sunday': sunDayWeath,
  };
  
  const daysWeather = weatherPaths[isActive];

  const handleDayClick = (e) => {
    if (e.target.nodeName === "LI") {
      setActive(e.target.children[0].getAttribute("data-target"));
    } else if (e.target.nodeName === "BUTTON") {
      setActive( e.target.getAttribute("data-target"));
    }
  };
  
  return (
    <div className={S.schedContainer}>
      <h1 className={S.schedHeader}> Daily Schedule</h1>
      <div
        onClick={handleDayClick} 
        className={S.dayTabs}>
        
        <li className={ isActive === 'thursday' ? S.active : null}>
          <button className={isActive === 'thursday' ? S.active : null}
            data-toggle="tab"
            data-target="thursday"
            href="#">Thursday
          </button>
        </li>

        <li className={isActive === 'friday' ? S.active : null}>
          <button className={isActive === 'friday' ? S.active : null}
            data-target="friday"
            href="#">Friday
          </button>
        </li>

        <li className={isActive === 'saturday' ? S.active : null}>
          <button className={isActive === 'saturday' ? S.active : null}
            data-target="saturday"
            href="#">Saturday
          </button>
        </li>

        <li className={isActive === 'sunday' ? S.active : null}>
          <button className={isActive === 'sunday' ? S.active : null}
            data-target="sunday"
            href="#">Sunday
          </button>
        </li>
      </div>
    
      <div className={S.dailySchedule}>
        <div className={S.weathContain}>
          <img alt="screenshot of daily weather" className={S.weathImg} src={daysWeather} />
        </div>

        { 
          data[isActive].map( (event, i) =>
          <div
            key={i}
            className={S.eventBox}
          >
            <div
              className={S.eContent}
            >
              <div className={S.eContentLeft}>
                <span className={S.time}>
                  {event.startTime}{event.startSuff}
                  {event.endTime ? ` - ${event.endTime}` : null}
                  {event.endSuff ? `${event.endSuff}` : null}
                </span>
              </div>

              <div className={S.eContentRight}>
                <h3>{event.name}</h3>
                {event.description.map((eDesc, i) =>
                  <p key={i}>{eDesc}</p>
                )}

                <div className={S.location}>
                  {event.mapsLink && event.linkText &&
                    <>
                      <img alt="gps pin icon" src={gpsIcon} />
                      {event.mapsLink === '/links' ? 
                        <a href={event.mapsLink}>{event.linkText}</a> :
                        <a rel="noreferrer" target="_blank" href={event.mapsLink}>{event.linkText}</a>
                      }
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Schedule;