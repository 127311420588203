import { useState } from 'react';
import S            from './Nav.module.scss';
import { Link }     from "react-router-dom";

function Nav() {
  const [navIsOpen, setNavIsOpen] = useState(false);
  const handleNavToggle = (e, toggleVal) => {
    setNavIsOpen(toggleVal);
  };

  const handleMenuClick = (e) => {
    if(e.target.localName === 'a') {
      setNavIsOpen(false);
    }
  };

  return (
    <nav role="navigation">
      <div className={S.menuToggle}>
        <input 
          className={navIsOpen ? S.active : S.closed}
          type="checkbox" 
          checked={navIsOpen}
          onChange={e => handleNavToggle(!navIsOpen)}
        />
        
        <span></span>
        <span></span>
        <span></span>
        
        <ul 
          className={S.menu}
          onClick={handleMenuClick}
        >
          <li> <Link to="/"> Home </Link> </li>
          <li> <Link to="/schedule"> Schedule </Link> </li>
          <li> <Link to="/links"> Links </Link> </li>
        </ul>
      </div>
    </nav>
  );
}

export default Nav;
