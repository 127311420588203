import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import Nav      from './common/Nav';
import Homepage from './pages/Homepage';
import Schedule from './pages/Schedule';
import Links    from './pages/Links';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import './assets/sass/index.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <div className="App">
    <BrowserRouter>
      <Nav />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="schedule" element={<Schedule />} />
        <Route path="links" element={<Links />} />
      </Routes>
    </BrowserRouter>
  </div>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// reportWebVitals(console.log);
